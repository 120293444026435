$delay: 0.5s;

.page-enter {
  z-index: 10;
  transform: translate3d(100vw, 0, 0);
  min-height: 100vh;
  width: 100vw; //Desktop

  &-active {
    transform: translate3d(0, 0, 0);
    transition: all $delay ease-in-out;
    pointer-events: none;

    position: absolute;
    top: 0;

    box-sizing: border-box; //New for desktop
    /*transition: transform $delay cubic-bezier(0, .52, 0, 1);*/
  }
}

.page-exit {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  //top: 50px;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  opacity: 1;

  //scroll-behavior: unset;
  //overflow: hidden;
  //.main-wrap {
  //  overflow: hidden;
  //  scroll-behavior: unset;
  //}

  .page-modal & {
    top: 0;
  }

  &-active {
    transform: translate3d(-100vw, 0, 0);
    transition: all $delay ease-in-out;
    opacity: 0;
    pointer-events: none;
    /*transition: transform $delay cubic-bezier(0, .52, 0, 1);*/
  }
}

.pageRight {
  .page-enter {
    transform: translate3d(-100vw, 0, 0);
  }
  .page-enter.page-enter-active {
    transform: translate3d(0, 0, 0);
  }

  .page-exit {
    transform: translate3d(0, 0, 0);
  }
  .page-exit.page-exit-active {
    transform: translate3d(100vw, 0, 0);
  }
}
//.content-middle.page-exit.page-exit-active {
//  position: absolute;
//  top: 0;
//}

.pageUp {
  .page-enter {
    z-index: 12;
    transform: translate3d(0, 100vh, 0);
  }
  .page-enter.page-enter-active {
    transform: translate3d(0, 0, 0);
    transition: all $delay ease-in-out;
  }

  .page-exit {
    transform: translate3d(0, 0, 0);
  }
  .page-exit.page-exit-active {
    transform: translate3d(0, 0, 0);
    transition: all $delay ease-in-out;
  }
}

.pageDown {
  .page-enter {
    z-index: 12;
    transform: translate3d(0, 0, 0);
  }
  .page-enter.page-enter-active {
    transform: translate3d(0, 0, 0);
    transition: all $delay ease-in-out;
  }

  .page-exit {
    position: absolute;
    width: 100vw;
    top: 55px;
    z-index: 1;
    transform: translate3d(0, 0, 0);
  }
  .page-exit.page-exit-active {
    transform: translate3d(0, 100vh, 0);
    transition: all $delay ease-in-out;
  }
}

/*------*/

//page-enter page-enter-active
//page-exit page-exit-active

//.fade-enter {
//  opacity: 0;
//  z-index: 1;
//}
////.fade-enter.fade-enter-active {
//  opacity: 1;
//  transition: opacity 250ms ease-in;
//}

.fade {
  position: relative;
  height: 100%;

  .page-enter {
    opacity: 0;
    z-index: 1;
    width: 100%;
  }
  .page-enter.page-enter-active {
    opacity: 1;
    transition: opacity $delay ease-in;
  }

  .page-exit {
  }
  .page-exit.page-exit-active {
    opacity: 0;
    transition: opacity $delay ease-in;
    //transform: translate3d(0, 0, 0);
  }
}

.page-modal {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: #fff;
  top: 0;
  z-index: 100;
  //overflow-y: scroll;

  transform: translate3d(0, 100vh, 0);
  transition: all $delay ease-in-out;

  &.visible {
    transform: translate3d(0, 0, 0);
  }

  &.menu {
    width: 90vw;
    transform: translate3d(-100vw, 0, 0);

    &.visible {
      transform: translate3d(0, 0, 0);
    }
  }

  &.menu-desktop {
    width: 340px;
    transform: translate3d(-100vw, 0, 0);

    &.visible {
      transform: translate3d(0, 0, 0);
    }
  }

  &.slideInRight {
    transform: translate3d(100vw, 0, 0);

    &.visible {
      transform: translate3d(0, 0, 0);
    }
  }
}

.full-page {
  min-height: calc(100vh - 100px);
  width: 100vw;
  position: absolute;
  background: #fff;
  z-index: 11;
  top: 0;
}

.higher-index {
  //position: relative;
  //z-index: 2;
}

.fade-item {
  animation: fadeAnimation 1s forwards;
}

@keyframes fadeAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.slide-up-item {
  animation: slideAnimation 0.5s forwards;
}

@keyframes slideAnimation {
  from {
    bottom: -100px;
  }
  to {
    bottom: 70px;
  }
}

.relative {
  position: relative;
  overflow: hidden;
}

//-------Tabs
.tab-enter {
  z-index: 10;
  transform: translate3d(100vw, 0, 0);

  .negative & {
    transform: translate3d(-100vw, 0, 0);
  }

  &.tab-enter-active {
    transform: translate3d(0, 0, 0);
    transition: all $delay ease-in-out;
    pointer-events: none;
  }
}

.tab-exit {
  position: absolute;
  width: 100vw;
  top: 0;
  z-index: 1;
  transform: translate3d(0, 0, 0);
  opacity: 1;

  &.tab-exit-active {
    transform: translate3d(-100vw, 0, 0);
    transition: all $delay ease-in-out;
    opacity: 0;
    pointer-events: none;

    .negative & {
      transform: translate3d(100vw, 0, 0);
    }
  }
}

.right-col {
  .page-exit,
  .page-enter {
    width: calc(50vw - 80px);
  }
}

.page-fadeIn {
  animation: fadeInOut 2s;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.page-fade {
  opacity: 1;

  transition: all $delay ease-in-out;

  &.fade-in {
    opacity: 0;
  }
}

//=========Page - fade
.page-fade-enter {
  z-index: 10;
  opacity: 0;
  min-height: 100vh;
  width: 100vw;
}

.page-fade-enter.page-fade-enter-active {
  opacity: 1;
  transition: all 1s ease-in-out;
  pointer-events: none;
  position: absolute;
  top: 0;
  box-sizing: border-box;
}

.page-fade-exit {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  z-index: 1;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}
.page-fade-exit.page-fade-exit-active {
  transition: all 1s ease-in-out;
  opacity: 0;
  pointer-events: none;
}
