$delay: 0.3s;

.modals-fade {
  position: relative;
  height: 100%;

  &-appear {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: opacity $delay ease-in;
    }
  }

  &-exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transition: opacity $delay ease-in;
    }
  }
}

.modal-slide {
  height: 100%;

  &-appear {
    transform: translate3d(100vw, 0, 0);
    position: absolute;
    z-index: 101;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-active {
      transform: translate3d(0, 0, 0);
      transition: all $delay ease-in-out;
      pointer-events: none;
    }
  }

  &-exit {
    transform: translate3d(0, 0, 0);
    position: absolute;
    z-index: 101;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &-active {
      transform: translate3d(100vw, 0, 0);
      transition: all $delay ease-out;
    }
  }
}
