@import 'animations';

html,
body {
  background-color: #eeeeee;
}

html {
  height: -webkit-fill-available;
  touch-action: none;
}

html,
html * {
  scroll-behavior: smooth;

  &.no-scroll,
  &.no-scroll * {
    overflow: hidden;
    scroll-behavior: inherit;
  }
}

body {
  height: -webkit-fill-available;
  height: 100%;
  min-height: 100%;
  min-height: -webkit-fill-available;
  font-family: Helvetica Neue;
  font-size: 14px;
  line-height: 21px;
}

#root {
  min-height: 100%;
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  // overflow-x: hidden;
}

.custom-checkbox-root {
  padding: 0 !important;

  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
}

.carousel-item {
  transition: transform 0.4s ease-in-out !important;
}

.secondary-button {
  display: block;
  margin-top: 20px;
  text-align: center;
}
//@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,700|Playfair+Display&display=swap');
